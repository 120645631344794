<template>
	<resource-form :id="id" type="case_study" plural="case_studies" v-model="case_study" title="Case study"></resource-form>
</template>

<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import resourceForm from '@/views/resources/Form'
import models from '@/models'

export default {
	name: "CaseStudiesForm",
	components: {resourceForm},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		case_study: sync('case_studies/case_study'),
	},
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('case_studies/getCase_study!', this.id)
			} else {
				this.case_study = new models.Resource('CaseStudy')
			}
		}
	}
}
</script>